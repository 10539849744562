import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import makeStyles from "@material-ui/styles/makeStyles";
import PageContext from "@PageContext";
import LayoutStandard from "../views/template/standard";
import Error404 from "../views/template/errors/404";
import Header from "../views/components/header";

const Animation = lazy(() =>
	import(/* webpackChunkName: "animation" */ "../views/components/animation")
);
const Hero = lazy(() => import(/* webpackChunkName: "hero" */ "../views/components/hero"));
const Footer = lazy(() =>
	import(/* webpackChunkName: "footer" */ "../views/components/footer")
);
const Bookmark = lazy(() =>
	import(/* webpackChunkName: "bookmark" */ "../components/bookmark")
);
const VoucherButton = lazy(() =>
	import(/* webpackChunkName: "bookmark" */ "../components/voucherButton")
);

const useStyles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		paddingTop: "calc(env(safe-area-inset-top))",
		overflow: "hidden",
		[theme.breakpoints.up("sm")]: {
			paddingTop: "calc(env(safe-area-inset-top))"
		}
	}
}));

const DetectLayout = () => {
	const [pageData] = useContext(PageContext);
	const { animationID, lang, layoutTag, layoutVariant, menuGroups, meta, state } =
		pageData || {};
	const [day, setDay] = useState(false);
	const location = useLocation();
	const css = useStyles();

	useEffect(() => {
		const hash = document.URL.split("#")[1];

		if (hash) {
			// try to find anchor

			setTimeout(() => {
				const $target = document.querySelector(`[data-anchor="${hash}"]`);

				if ($target) {
					$target.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			}, 1000);
		}
	}, [location]);

	// get day mode

	const hours = new Date().getHours();

	useEffect(() => {
		setDay(hours >= 6 && hours < 18);
	}, [hours]);

	if (isEmpty(pageData)) {
		return <></>;
	}

	const mainmenu = menuGroups?.find(item => item.title === "Hauptmenü");
	const metamenu = menuGroups?.find(item => item.title === "Metamenü");

	switch (layoutTag) {
		case "standard":
			return (
				<div className={clsx(css.page, "mco-page")}>
					<Header
						lang={lang}
						mainmenu={mainmenu}
						metamenu={metamenu}
						mode={day}
						fixed={layoutVariant === "no-animation" && layoutVariant === "map"}
						variant={layoutVariant}
					/>
					{layoutVariant !== "intro" &&
						layoutVariant !== "no-animation" &&
						layoutVariant !== "map" && (
							<Suspense fallback={<></>}>
								<Animation
									animationID={animationID}
									lang={lang}
									layoutVariant={layoutVariant}
								/>
							</Suspense>
						)}
					{layoutVariant === "intro" && <VoucherButton lang={lang} />}
					{layoutVariant === "intro" && (
						<Suspense fallback={<></>}>
							<Hero mode={day} lang={lang} />
						</Suspense>
					)}
					<LayoutStandard variant={layoutVariant} />
					<Suspense fallback={<></>}>
						<Footer lang={lang} />
					</Suspense>
					<div className={clsx("bookmark", css.bookmark)}>
						<Suspense fallback={<></>}>
							<Bookmark />
						</Suspense>
					</div>
				</div>
			);
		case "room-detail":
			return (
				<div className={clsx(css.page, "mco-page room-detail")}>
					<Header
						lang={lang}
						mainmenu={mainmenu}
						metamenu={metamenu}
						variant="no-animation"
						title={meta?.title}
					/>
					<Suspense fallback={<></>}>
						<LayoutStandard variant="no-animation" />
						<Footer lang={lang} />
					</Suspense>
				</div>
			);
		case "room-list":
			return (
				<div className={clsx(css.page, "mco-page room-overview")}>
					<Header lang={lang} mainmenu={mainmenu} metamenu={metamenu} variant="no-animation" />
					<Suspense fallback={<></>}>
						<LayoutStandard variant="no-animation" />
						<Footer lang={lang} />
					</Suspense>
				</div>
			);
		case "blog":
			return (
				<div className={clsx(css.page, "mco-page blog-detail")}>
					<Header lang={lang} mainmenu={mainmenu} metamenu={metamenu} variant="no-animation" />
					<Suspense fallback={<></>}>
						<LayoutStandard variant="blog" />
						<Footer lang={lang} />
					</Suspense>
				</div>
			);
		default: {
			// errors

			// eslint-disable-next-line default-case
			switch (state) {
				case 404:
				default:
					return (
						<div className={clsx(css.page, "mco-page")}>
							<Header lang={lang} mainmenu={mainmenu} />
							<Suspense fallback={<></>}>
								<Error404 />
								<Footer />
							</Suspense>
						</div>
					);
			}
		}
	}
};

export default DetectLayout;
